<template>
  <!--begin::List Widget 9-->
  <div class="">
    <!--begin::Header-->
    <div class="col-12 row m-0 p-0 bg-white">
      <div class="col-6 py-4 px-4 border_b_silver">
        <span class="card-label font-weight-bolder Main_Blue"
          >News & Trends
        </span>
      </div>
      <div class="col-6 p-0 pt-3 text-end border_b_silver">
        <!-- <button
          type="button"
          fab=""
          small=""
          class="custom-add-new-record-button btn_dash_ch mr-2"
        >
          <Dropdown2><i class="ki ki-bold-more-hor"></i></Dropdown2>
        </button> -->
      </div>
    </div>
    <!-- <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title align-items-start flex-column">
        <span class="font-weight-bolder text-dark"> News & Trends </span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm"> </span>
      </h3>
      <div class="card-toolbar">
        <Dropdown2><i class="ki ki-bold-more-hor"></i></Dropdown2>
      </div>
    </div> -->
    <!--end::Header-->

    <!--begin::Body-->
    <div
      class="
        v-data-table
        rounded-0
        table-borderless
        px-4
        v-data-table--has-bottom
        theme--light
        py-4
      "
    >
      <div class="timeline timeline-6 mt-3">
        <template v-for="(item, i) in list">
          <!--begin::Item-->
          <div class="timeline-item align-items-start" v-bind:key="i">
            <!--begin::Label-->
            <div
              class="
                timeline-label
                font-weight-bolder
                text-dark-75
                font-size-lg
              "
            >
              {{ moment(item.created_at).format("hh:mm") }}
            </div>
            <!--end::Label-->

            <!--begin::Badge-->
            <div class="timeline-badge">
              <i class="icon-xl fa fa-genderless text-success"></i>
            </div>
            <!--end::Badge-->
            <a target="_blank" :href="item.link">{{ item.heading }}</a>
          </div>
          <!--end::Item-->
        </template>
      </div>
      <!--end: Items-->
    </div>
    <!--end: Card Body-->
  </div>
  <!--end: Card-->
  <!--end: List Widget 9-->
</template>

<script>
import Dropdown2 from "@/view/content/dropdown/Dropdown2.vue";
import { mapGetters } from "vuex";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
import moment from "moment";

export default {
  name: "widget-9",
  data() {
    return {
      list: [],
    };
  },
  components: {
    Dropdown2,
  },
  mounted() {
    this.getInititalData();
  },
  methods: {
    lastElement(i) {
      if (i === this.list.length - 1) {
        return false;
      } else {
        return true;
      }
    },
    moment: function (date) {
      return moment(date);
    },
    getInititalData() {
      axios.get(`${API_URL}/get-news-alert`).then((res) => {
        this.list = res.data;
        // console.log("here: ", res.data);
      });
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
};
</script>
