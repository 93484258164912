<template>
  <!--begin::Advance Table Widget 9-->
  <div class="" v-if="recruiter">
    <!--begin::Header-->
    <div class="col-12 row m-0 p-0 bg-white">
      <div class="col-6 py-4 px-4 border_b_silver">
        <span class="card-label font-weight-bolder Main_Blue"
          >List of Applicantions</span
        >
      </div>
      <div class="col-6 p-0 pt-3 text-end border_b_silver">
        <button
          type="button"
          fab=""
          small=""
          class="custom-add-new-record-button btn_dash_ch mr-2"
        >
          <span class="v-btn__content" style="font-size: 14px !important"
            ><a href="/recruiter/jobs">Advertise job</a></span
          >
        </button>
      </div>
    </div>
    <!-- <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"
          >List of Applicantions</span
        >
      </h3>
      <div class="card-toolbar">
        <a class="btn btn-info font-weight-bolder font-size-sm mr-3"
          >Advertise job</a
        >
      </div>
    </div> -->
    <!--end::Header-->
    <!--begin::Body-->
    <div class="px-4 pb-4 bg-white">
      <div class="tab-content">
        <!--begin::Table-->
        <div class="table-responsive">
          <table
            class="
              table
              table-head-custom
              table-vertical-center
              table-head-bg
              table-borderless
            "
          >
            <thead>
              <tr class="text-left thead_by_ch">
                <th style="" class="pl-7">
                  <span class="text-dark-75">Applicant</span>
                </th>
                <th style="">Job Title</th>
                <th style="">Expected Salary</th>
                <th style="">Experience (years)</th>
                <th style=""></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in jobApplications">
                <tr class="border__ch mb-4" v-bind:key="i">
                  <td class="pl-0 pt-8">
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-50 symbol-light mr-4">
                        <span class="symbol-label ch_img_mangeer">
                          <img
                            :src="
                              mediaUrl + item.job_seeker_profile.profile_pic
                            "
                            class=""
                            alt=""
                          />
                        </span>
                      </div>
                      <div>
                        <a
                          class="m-0 Main_Blue"
                          style="font-size: 14px; font-weight: 600"
                          >{{ item.job_seeker.name }}</a
                        >
                      </div>
                    </div>
                  </td>
                  <td>
                    <span class="m-0 Main_Blue" style="font-size: 14px">{{
                      item.job.job_title
                    }}</span>
                  </td>
                  <td>
                    <span
                      class="m-0 Main_Blue text-center"
                      style="font-size: 14px"
                    >
                      <span
                        class="m-0 Main_Blue text-center pl-5"
                        style="font-size: 14px"
                      >
                        {{ item.job_seeker_profile.desired_salary }}
                      </span></span
                    >
                  </td>
                  <td>
                    <span
                      class="m-0 Main_Blue text-center pl-5"
                      style="font-size: 14px; font-weight: 600"
                      >{{ item.job.minimum_experience_years }}</span
                    >
                  </td>
                  <td class="pr-0 text-right">
                    <router-link
                      :to="'/recruiter/job-application-detail/' + item.id"
                      class="custom-add-new-record-button btn_dash_ch mr-2"
                    >
                      View application
                    </router-link>
                  </td>
                </tr>
                <tr v-bind:key="i">
                  <td colspan="12" style="height: 12px"></td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<script>
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";

export default {
  name: "widget-3",
  data() {
    return {
      mediaUrl: MEDIA_URL,
      token: `Bearer ${localStorage.getItem("token")}`,
      jobApplications: [],
      recruiter:
        JSON.parse(localStorage.getItem("user")).role == "Direct Employer"
          ? true
          : false,
    };
  },
  mounted() {
    this.getInititalData();
  },
  methods: {
    getInititalData() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/job-applications`)
        .then((res) => {
          // console.log("res:", res.data);
          this.jobApplications = res.data;
          localStorage.setItem("jobApplications", JSON.stringify(res.data));
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
