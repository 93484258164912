<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="py-4 px-4 border_b_silver">
      <span class="card-label font-weight-bolder Main_Blue">Saved Jobs</span>
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="pt-0 pb-3 px-4">
      <div class="tab-content">
        <!--begin::Table-->

        <div class="table-responsive">
          <table class="table table-vertical-center table-borderless">
            <thead>
              <tr>
                <th style="min-width: 250px" class="pl-7">
                  <span class="text-dark-75">COMPANY</span>
                </th>
                <th style="min-width: 120px">JOB TITLE</th>
                <th style="min-width: 100px">CITY</th>
                <th style="min-width: 100px">JOB TYPE</th>
                <th style="min-width: 101px">ACTIONS</th>
              </tr>
            </thead>
            <tbody v-if="data.length > 0">
              <template v-for="(item, i) in data">
                <tr v-bind:key="i" class="border__ch mb-4">
                  <td class="p-0">
                    <div class="d-flex align-items-center">
                      <div class="mr-4">
                        <img
                          :src="mediaUrl + item.recruiter_profile.profile_pic"
                          alt=""
                          height="40"
                          width="40"
                          class="p-1"
                        />
                      </div>
                      <div>
                        <router-link
                          target="_blank"
                          :to="
                            '/employer/' +
                            item.recruiter_profile.company_name
                              .toLowerCase()
                              .replace(/ /g, '-')
                              .replace(/[^\w-]+/g, '')
                          "
                          class="font-weight-bolder Main_Blue mb-1"
                          style="font-size: 14px"
                          >{{
                            item.recruiter_profile.company_name
                          }}</router-link
                        >
                        <span class="text-muted font-weight-bold d-block">{{
                          item.desc
                        }}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span
                      class="d-block Main_Blue"
                      style="font-size: 13px; line-height: 1"
                      >{{ item.job_title }}</span
                    >
                    <span
                      class=""
                      style="font-size: 12px; color: rgb(119, 119, 119)"
                      >({{ item.job_function }})</span
                    >
                  </td>
                  <td>
                    <span
                      class="d-block Main_Blue"
                      style="font-size: 13px; line-height: 1"
                      >{{ item.location_city.toString() }}
                    </span>
                  </td>
                  <td>
                    <span
                      class="d-block Main_Blue"
                      style="font-size: 13px; line-height: 1"
                      >{{ item.job_type.toString() }}</span
                    >
                  </td>

                  <td class="pr-0 text-left pl-4">
                    <v-btn
                      class="py-2 ch_btn ml-4"
                      fab
                      small
                      @click="viewJob(item)"
                    >
                      <img
                        src="\images/job_application/View_Icon.svg"
                        alt=""
                        class="dashbord"
                        width="16"
                        height="16"
                      />
                    </v-btn>
                    <v-btn
                      class="py-2 ch_btn"
                      fab
                      small
                      @click="deleteJob(item)"
                    >
                      <img
                        src="\images/job_application/Delete.svg"
                        alt=""
                        class="dashbord"
                        width="16"
                        height="16"
                      />
                    </v-btn>
                  </td>
                </tr>
                <tr v-bind:key="i">
                  <td style="height: 12px" colspan="12"></td>
                </tr>
              </template>
            </tbody>
            <tbody v-if="data.length === 0">
              <tr>
                <td colspan="4" style="text-align: center">No data found</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<script>
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";

export default {
  data() {
    return {
      search: "",
      loading: true,
      success: false,
      mediaUrl: MEDIA_URL,
      token: `Bearer ${localStorage.getItem("token")}`,
      headers: [
        { text: "Job Title", value: "job_title" },
        { text: "City", value: "location_city" },
        { text: "Salary Min.", value: "salary_min" },
        { text: "Salary Max.", value: "salary_max" },
        { text: "Job Type", value: "job_type" },
        { text: "Action", value: "action" },
      ],
      data: [],
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/get-save-jobs`)
        .then((res) => {
          this.data = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    viewJob(item) {
      let routeData = this.$router.resolve({
        path:
          "/jobs-careers/pakistan/" +
          item.location_city.map((v) => v.toLowerCase()).join("-") +
          "/" +
          item.job_title
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\w-]+/g, "") +
          "/" +
          item.id,
        params: {
          search_text: this.search_text,
          selected_cities: [{ id: 1, value: item }],
        },
      });
      window.open(routeData.href, "_blank");
    },
    deleteJob(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .delete(`${API_URL}/delete-save-job/${item.id}`)
        .then((res) => {
          this.fetch();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
