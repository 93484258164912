<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b mb-4">
    <!--begin::Header-->
    <!--begin::Header-->
    <div class="py-4 px-4 border_b_silver">
      <span class="card-label font-weight-bolder Main_Blue"
        >Company Watch List</span
      >
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="pt-0 pb-0 px-4 bg-white">
      <!-- <div class="tab-content"> -->
      <!--begin::Table-->

      <div class="table-responsive mb-0">
        <table class="table table-vertical-center table-borderless">
          <thead>
            <tr>
              <th class="p-0" style="width: 60px"></th>
              <th class="p-0" style=""></th>
              <th class="p-0" style=""></th>
              <th class="p-0" style=""></th>
            </tr>
          </thead>
          <tbody v-if="data.length > 0">
            <template v-for="(item, i) in data">
              <tr v-bind:key="i">
                <td style="height: 12px" colspan="12"></td>
              </tr>
              <tr v-bind:key="i" class="border__ch mb-4">
                <td class="p-0" style="min-width: 45px; width: 45px">
                  <div class="p-0 mr-0">
                    <img
                      :src="mediaUrl + item.recruiter.profile_pic"
                      alt=""
                      height="40"
                      width="40"
                      class="p-1"
                    />
                    <!-- <span class="symbol-label">
                      <img
                        :src="mediaUrl + item.recruiter.profile_pic"
                        class="h-50 align-self-center"
                        alt=""
                      />
                    </span> -->
                  </div>
                </td>
                <td class="pl-0">
                  <router-link
                    :to="
                      '/employer/' +
                      item.recruiter.company_name
                        .toLowerCase()
                        .replace(/ /g, '-')
                        .replace(/[^\w-]+/g, '')
                    "
                    target="_blank"
                  >
                    <span
                      class="font-weight-bolder Main_Blue mb-1"
                      style="font-size: 14px"
                      >{{ item.recruiter.company_name }}</span
                    >
                  </router-link>

                  <div>
                    <a
                      style="
                        color: rgb(119, 119, 119) !important;
                        font-size: 12px;
                      "
                    >
                      {{ item.recruiter.office_phone_1 }}
                    </a>
                  </div>
                </td>
                <td>
                  <span
                    class="d-block Main_Blue pt-2"
                    style="font-size: 13px; line-height: 1"
                  >
                    <router-link
                      :to="
                        '/jobs-careers/pakistan/' +
                        item.job.location_city
                          .map((v) => v.toLowerCase())
                          .join('-') +
                        '/' +
                        item.job.job_title
                          .toLowerCase()
                          .replace(/ /g, '-')
                          .replace(/[^\w-]+/g, '') +
                        '/' +
                        item.id
                      "
                      target="_blank"
                    >
                      {{ item.job.job_title }}
                    </router-link>
                  </span>
                  <p style="font-size: 12px; color: rgb(119, 119, 119)">
                    {{ item.job.salary_min }} - {{ item.job.salary_max }}
                  </p>
                </td>
                <td>
                  <span class="text-muted font-weight-500">
                    {{ item.job.job_function }}
                  </span>
                </td>
                <td class="pr-0 pl-4">
                  <v-btn
                    class="py-2 ch_btn ml-4"
                    fab
                    small
                    @click.prevent="viewJob(item.job, item.id)"
                  >
                    <img
                      src="\images/job_application/View_Icon.svg"
                      alt=""
                      class="dashbord"
                      width="16"
                      height="16"
                    />
                  </v-btn>
                  <v-btn
                    class="py-2 ch_btn"
                    fab
                    small
                    @click.prevent="read(item.id)"
                  >
                    <img
                      src="\images/job_application/Delete.svg"
                      alt=""
                      class="dashbord"
                      width="16"
                      height="16"
                    />
                  </v-btn>
                </td>
                <!-- <td class="text-right pr-0">
                  <a
                    class="btn btn-icon btn-light btn-sm"
                    style="margin-right: 3%"
                    @click.prevent="viewJob(item.job, item.id)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                     
                      <inline-svg
                        src="media/svg/icons/General/Visible.svg"
                      ></inline-svg>
                      
                    </span>
                  </a>
                  <a
                    @click.prevent="read(item.id)"
                    class="btn btn-icon btn-light btn-sm"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                     
                      <inline-svg
                        src="media/svg/icons/General/Trash.svg"
                      ></inline-svg>
                     
                    </span>
                  </a>
                </td> -->
              </tr>
              <tr v-bind:key="i">
                <td style="height: 12px" colspan="12"></td>
              </tr>
            </template>
          </tbody>
          <tbody v-if="data.length === 0">
            <tr>
              <td colspan="4" style="text-align: center">No data found</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->
  </div>
</template>

<script>
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
export default {
  name: "widget-3",
  data() {
    return {
      data: [],
      mediaUrl: MEDIA_URL,
      token: `Bearer ${localStorage.getItem("token")}`,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/get-followup-companies-jobs`)
        .then((res) => {
          this.data = res.data;
          localStorage.setItem("followupJobs", JSON.stringify(this.data));
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    viewJob(item, id) {
      this.read(id);
      this.$router.push({
        path:
          "/jobs-careers/pakistan/" +
          item.location_city.map((v) => v.toLowerCase()).join("-") +
          "/" +
          item.job_title
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\w-]+/g, "") +
          "/" +
          item.id,
        params: {
          search_text: this.search_text,
          selected_cities: [{ id: 1, value: item }],
        },
      });
    },

    read(id) {
      axios
        .get(`${API_URL}/read-company-watch-list-alert/${id}`)
        .then((res) => {
          this.fetch();
        });
    },
  },
};
</script>

