var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-custom card-stretch gutter-b mb-4"},[_vm._m(0),_c('div',{staticClass:"pt-0 pb-0 px-4 bg-white"},[_c('div',{staticClass:"table-responsive mb-0"},[_c('table',{staticClass:"table table-vertical-center table-borderless"},[_vm._m(1),(_vm.data.length > 0)?_c('tbody',[_vm._l((_vm.data),function(item,i){return [_c('tr',{key:i},[_c('td',{staticStyle:{"height":"12px"},attrs:{"colspan":"12"}})]),_c('tr',{key:i,staticClass:"border__ch mb-4"},[_c('td',{staticClass:"p-0",staticStyle:{"min-width":"45px","width":"45px"}},[_c('div',{staticClass:"p-0 mr-0"},[_c('img',{staticClass:"p-1",attrs:{"src":_vm.mediaUrl + item.recruiter.profile_pic,"alt":"","height":"40","width":"40"}})])]),_c('td',{staticClass:"pl-0"},[_c('router-link',{attrs:{"to":'/employer/' +
                    item.recruiter.company_name
                      .toLowerCase()
                      .replace(/ /g, '-')
                      .replace(/[^\w-]+/g, ''),"target":"_blank"}},[_c('span',{staticClass:"font-weight-bolder Main_Blue mb-1",staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(item.recruiter.company_name))])]),_c('div',[_c('a',{staticStyle:{"color":"rgb(119, 119, 119) !important","font-size":"12px"}},[_vm._v(" "+_vm._s(item.recruiter.office_phone_1)+" ")])])],1),_c('td',[_c('span',{staticClass:"d-block Main_Blue pt-2",staticStyle:{"font-size":"13px","line-height":"1"}},[_c('router-link',{attrs:{"to":'/jobs-careers/pakistan/' +
                      item.job.location_city
                        .map(function (v) { return v.toLowerCase(); })
                        .join('-') +
                      '/' +
                      item.job.job_title
                        .toLowerCase()
                        .replace(/ /g, '-')
                        .replace(/[^\w-]+/g, '') +
                      '/' +
                      item.id,"target":"_blank"}},[_vm._v(" "+_vm._s(item.job.job_title)+" ")])],1),_c('p',{staticStyle:{"font-size":"12px","color":"rgb(119, 119, 119)"}},[_vm._v(" "+_vm._s(item.job.salary_min)+" - "+_vm._s(item.job.salary_max)+" ")])]),_c('td',[_c('span',{staticClass:"text-muted font-weight-500"},[_vm._v(" "+_vm._s(item.job.job_function)+" ")])]),_c('td',{staticClass:"pr-0 pl-4"},[_c('v-btn',{staticClass:"py-2 ch_btn ml-4",attrs:{"fab":"","small":""},on:{"click":function($event){$event.preventDefault();return _vm.viewJob(item.job, item.id)}}},[_c('img',{staticClass:"dashbord",attrs:{"src":"\\images/job_application/View_Icon.svg","alt":"","width":"16","height":"16"}})]),_c('v-btn',{staticClass:"py-2 ch_btn",attrs:{"fab":"","small":""},on:{"click":function($event){$event.preventDefault();return _vm.read(item.id)}}},[_c('img',{staticClass:"dashbord",attrs:{"src":"\\images/job_application/Delete.svg","alt":"","width":"16","height":"16"}})])],1)]),_c('tr',{key:i},[_c('td',{staticStyle:{"height":"12px"},attrs:{"colspan":"12"}})])]})],2):_vm._e(),(_vm.data.length === 0)?_c('tbody',[_vm._m(2)]):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-4 px-4 border_b_silver"},[_c('span',{staticClass:"card-label font-weight-bolder Main_Blue"},[_vm._v("Company Watch List")]),_c('div',{staticClass:"card-toolbar"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"p-0",staticStyle:{"width":"60px"}}),_c('th',{staticClass:"p-0"}),_c('th',{staticClass:"p-0"}),_c('th',{staticClass:"p-0"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":"4"}},[_vm._v("No data found")])])}]

export { render, staticRenderFns }