<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-lg-6 order-2 order-xxl-2 pt-0" v-if="recruiter">
        <MixedWidget1></MixedWidget1>
      </div>
      <div class="row">
        <div class="col-xxl-8 order-1 order-xxl-1 pr-0">
          <div v-if="jobSeeker" class="">
            <jobapplicationslist></jobapplicationslist>
          </div>
          <div v-if="jobSeeker">
            <savejobWidgettable></savejobWidgettable>
          </div>
        </div>
        <div class="col-xxl-4 order-2 order-xxl-1 mt-5">
          <!-- <ListWidget1></ListWidget1> -->
          <!--begin::List Widget 1-->
          <div class="card card-custom card-stretch gutter-b" v-if="jobSeeker">
            <!--begin::Header-->
            <div class="py-4 px-4 border_b_silver mb-4">
              <span class="card-label font-weight-bolder Main_Blue"
                >Spotlight Companies</span
              >
              <div class="d-none">
                <Dropdown3></Dropdown3>
              </div>
            </div>
            <!-- <div class="py-4 px-4 border_b_silver">
              <h3 class="card-label font-weight-bolder Main_Blue">
                Spotlight Companies
              </h3>
              <div class="d-none">
                <Dropdown3></Dropdown3>
              </div>
            </div> -->
            <!--end::Header-->
            <!--begin::Body-->
            <div class="px-4">
              <template
                v-for="(item, i) in spotlightCompanies && spotlightCompanies"
              >
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-4" v-bind:key="i">
                  <!--begin::Symbol-->
                  <div
                    class="pic_border s"
                    v-bind:class="`symbol-light-${item.style}`"
                  >
                    <span class="">
                      <span
                        class="svg-icon svg-icon-lg"
                        v-bind:class="`svg-icon-${item.style}`"
                      >
                        <!--begin::Svg Icon-->
                        <router-link
                          :to="
                            '/employer/' +
                            item.company_name
                              .toLowerCase()
                              .replace(/ /g, '-')
                              .replace(/[^\w-]+/g, '')
                          "
                          target="_blank"
                        >
                          <img
                            :src="mediaUrl + item.profile_pic"
                            width="40"
                            height="40"
                            class="p-1"
                        /></router-link>
                        <!--end::Svg Icon-->
                      </span>
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column font-weight-bold pt-2">
                    <a
                      class="font-weight-bolder Main_Blue mb-1"
                      style="font-size: 14px"
                    >
                      <router-link
                        class="Main_Blue"
                        style="font-size: 14px"
                        :to="
                          '/employer/' +
                          item.company_name
                            .toLowerCase()
                            .replace(/ /g, '-')
                            .replace(/[^\w-]+/g, '')
                        "
                        target="_blank"
                        >{{ item && item.company_name }}</router-link
                      >

                      &nbsp;({{ item.job_count }})
                      <!-- Company Name -->
                    </a>
                    <span style="font-size: 12px; color: rgb(119, 119, 119)">
                      {{ item.website }}
                    </span>
                  </div>
                  <!--end::Text-->
                </div>
                <!--end::Item-->
              </template>
              <p v-if="spotlightCompanies && spotlightCompanies.length === 0">
                No spotlight company found!
              </p>
            </div>
            <!--end::Body-->
          </div>
          <!--end: List Widget 1-->
        </div>
      </div>
      <div class="col-xxl-8 order-2 order-xxl-1" v-if="jobSeeker === true">
        <AdvancedTableWidget2></AdvancedTableWidget2>
      </div>

      <div class="col-lg-6 order-2 order-xxl-2 order-lg-2 pt-0">
        <!-- <savejobWidgettable></savejobWidgettable> -->
        <ListWidget9></ListWidget9>
      </div>

      <!-- <div class="col-xxl-4 order-7 order-xxl-1">
        <StatsWidget7></StatsWidget7>
        <StatsWidget12></StatsWidget12>
      </div> -->
      <!-- <div class="col-xxl-4 order-1 order-xxl-2">
        <StatsWidget13></StatsWidget13>
      </div> -->
      <div class="col-xxl-4 order-1 order-xxl-2">
        <ListWidget3></ListWidget3>
      </div>
      <div class="col-xxl-4 order-1 order-xxl-2">
        <ListWidget4></ListWidget4>
      </div>
      <!-- <div class="col-lg-12 col-xxl-4 order-1 order-xxl-2">
        <ListWidget8></ListWidget8>
      </div> -->
      <div class="col-lg-12 order-1 order-xxl-1 pt-4" v-if="recruiter === true">
        <AdvancedTableWidget3></AdvancedTableWidget3>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
import savejobWidgettable from "@/view/content/widgets/advance-table/savejobWidget.vue";
import jobapplicationslist from "@/view/content/widgets/advance-table/Jobapplicationslist.vue";
import AdvancedTableWidget3 from "@/view/content/widgets/advance-table/Widget3.vue";
import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
// import ListWidget1 from "@/view/content/widgets/list/Widget1.vue";
import ListWidget3 from "@/view/content/widgets/list/Widget3.vue";
import ListWidget4 from "@/view/content/widgets/list/Widget4.vue";
import ListWidget8 from "@/view/content/widgets/list/Widget8.vue";
import ListWidget9 from "@/view/content/widgets/list/Widget9.vue";
import StatsWidget7 from "@/view/content/widgets/stats/Widget7.vue";
import StatsWidget12 from "@/view/content/widgets/stats/Widget12.vue";
import StatsWidget13 from "@/view/content/widgets/stats/Widget13.vue";
import Dropdown3 from "@/view/content/dropdown/Dropdown3.vue";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";

export default {
  data() {
    return {
      spotlightCompanies: null,
      token: `Bearer ${localStorage.getItem("token")}`,
      mediaUrl: MEDIA_URL,
      jobSeeker:
        JSON.parse(localStorage.getItem("user")).role == "Job Seeker"
          ? true
          : false,
      recruiter:
        JSON.parse(localStorage.getItem("user")).role == "Direct Employer"
          ? true
          : false,
    };
  },
  name: "dashboard",
  components: {
    AdvancedTableWidget2,
    savejobWidgettable,
    jobapplicationslist,
    AdvancedTableWidget3,
    MixedWidget1,
    // ListWidget1,
    ListWidget3,
    ListWidget4,
    ListWidget8,
    ListWidget9,
    StatsWidget7,
    StatsWidget12,
    StatsWidget13,
    Dropdown3,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    axios.defaults.headers.common["Authorization"] = this.token;

    axios.get(`${API_URL}/get-spotlight-companies`).then((response) => {
      this.spotlightCompanies = response.data;
      localStorage.setItem(
        "spotlightCompanies",
        JSON.stringify(this.spotlightCompanies)
      );
    });
    axios.get(`${API_URL}/get-inbox`).then((response) => {
      localStorage.setItem("messages", JSON.stringify(response.data));
    });
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>
