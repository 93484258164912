var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[(_vm.recruiter)?_c('div',{staticClass:"col-lg-6 order-2 order-xxl-2 pt-0"},[_c('MixedWidget1')],1):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxl-8 order-1 order-xxl-1 pr-0"},[(_vm.jobSeeker)?_c('div',{},[_c('jobapplicationslist')],1):_vm._e(),(_vm.jobSeeker)?_c('div',[_c('savejobWidgettable')],1):_vm._e()]),_c('div',{staticClass:"col-xxl-4 order-2 order-xxl-1 mt-5"},[(_vm.jobSeeker)?_c('div',{staticClass:"card card-custom card-stretch gutter-b"},[_c('div',{staticClass:"py-4 px-4 border_b_silver mb-4"},[_c('span',{staticClass:"card-label font-weight-bolder Main_Blue"},[_vm._v("Spotlight Companies")]),_c('div',{staticClass:"d-none"},[_c('Dropdown3')],1)]),_c('div',{staticClass:"px-4"},[_vm._l((_vm.spotlightCompanies && _vm.spotlightCompanies),function(item,i){return [_c('div',{key:i,staticClass:"d-flex align-items-center mb-4"},[_c('div',{staticClass:"pic_border s",class:("symbol-light-" + (item.style))},[_c('span',{},[_c('span',{staticClass:"svg-icon svg-icon-lg",class:("svg-icon-" + (item.style))},[_c('router-link',{attrs:{"to":'/employer/' +
                          item.company_name
                            .toLowerCase()
                            .replace(/ /g, '-')
                            .replace(/[^\w-]+/g, ''),"target":"_blank"}},[_c('img',{staticClass:"p-1",attrs:{"src":_vm.mediaUrl + item.profile_pic,"width":"40","height":"40"}})])],1)])]),_c('div',{staticClass:"d-flex flex-column font-weight-bold pt-2"},[_c('a',{staticClass:"font-weight-bolder Main_Blue mb-1",staticStyle:{"font-size":"14px"}},[_c('router-link',{staticClass:"Main_Blue",staticStyle:{"font-size":"14px"},attrs:{"to":'/employer/' +
                        item.company_name
                          .toLowerCase()
                          .replace(/ /g, '-')
                          .replace(/[^\w-]+/g, ''),"target":"_blank"}},[_vm._v(_vm._s(item && item.company_name))]),_vm._v("  ("+_vm._s(item.job_count)+") ")],1),_c('span',{staticStyle:{"font-size":"12px","color":"rgb(119, 119, 119)"}},[_vm._v(" "+_vm._s(item.website)+" ")])])])]}),(_vm.spotlightCompanies && _vm.spotlightCompanies.length === 0)?_c('p',[_vm._v(" No spotlight company found! ")]):_vm._e()],2)]):_vm._e()])]),(_vm.jobSeeker === true)?_c('div',{staticClass:"col-xxl-8 order-2 order-xxl-1"},[_c('AdvancedTableWidget2')],1):_vm._e(),_c('div',{staticClass:"col-lg-6 order-2 order-xxl-2 order-lg-2 pt-0"},[_c('ListWidget9')],1),_c('div',{staticClass:"col-xxl-4 order-1 order-xxl-2"},[_c('ListWidget3')],1),_c('div',{staticClass:"col-xxl-4 order-1 order-xxl-2"},[_c('ListWidget4')],1),(_vm.recruiter === true)?_c('div',{staticClass:"col-lg-12 order-1 order-xxl-1 pt-4"},[_c('AdvancedTableWidget3')],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }